body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.white_button{
  margin-right: 12px;
  border: 0;
  height: auto;
  min-height:24px;
  min-width:88px;
  width:auto;
  font-size:12px;
  background: #FFFFFF;
  background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
  border-radius: 3px;
  color:black !important;
}

.blue_button{
  height: auto;
  min-height:24px;
  min-width:75px;
  width:auto;
  background-image: linear-gradient(0deg, #007EF4 0%, #0788DE 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20);
  border-radius: 3px;
  font-size:12px;
  border: 0;
  color:white;

}